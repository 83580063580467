<template>
  <div
    v-if="showFilterCategory"
    class="m-brands-filter-category-wrapper"
    :class="{'m-brands-filter-category--show': showFilterCategory}"
  >
    <ul class="m-brands-filter-category">
      <li
        class="m-brands-filter-category__item"
        :class="{'m-brands-filter-category__item--active': isActive(category.id)}"
        v-for="(category, i) in categories"
        :key="i"
        @click="filterByCategory(category)"
      >
        {{ category.name }}
        <span
          class="m-brands-filter-category__item--count"
          v-if="category.brand_data"
          v-html="category.brand_data.length"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { isServer } from '@vue-storefront/core/helpers';
export default {
  name: 'MBrandsFilterCategory',
  props: {
    activeCategory: {
      type: Object,
      default: null
    },
    categories: {
      type: Array,
      default: () => []
    },
    showFilterCategory: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    filterByCategory (category) {
      this.$emit('filterByCategory', category);
    },
    isActive (name) {
      return name === this.activeCategory.id
    },
    autoScrollCategory () {
      if (isServer) return
      this.autoScrollCategoryInterval = setInterval(() => {
        const activeLink = document.querySelector('.m-brands-filter-category__item--active');
        if (activeLink !== null) {
          const containerOffsetTop = document.querySelector('.m-brands-filter-category').getBoundingClientRect().top;
          const elementOffsetTop = activeLink.getBoundingClientRect().top;
          const offsetTop = elementOffsetTop - containerOffsetTop;
          document.querySelector('.m-brands-filter-category').scrollTo({
            top: offsetTop,
            left: 0,
            behavior: 'smooth'
          });
          clearInterval(this.autoScrollCategoryInterval);
        }
      }, 200);
    }
  },
  watch: {
    showFilterCategory (prev) {
      if (!prev) return
      this.$nextTick(() => {
        this.autoScrollCategory()
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-brands-filter-category-wrapper {
  display: none;
  padding: 0 var(--spacer-10);

  &.m-brands-filter-category--show {
    display: block;
  }

  @include for-tablet {
    padding: 0 var(--spacer-18);
  }

  @include for-desktop {
    display: block;
    padding: 0;
  }
}

.m-brands-filter-category {
  height: px2rem(340);
  margin: var(--spacer--20) var(--spacer-10) var(--spacer-10);
  border-radius: var(--spacer-10);
  background: var(--accordion-background);
  overflow: scroll;
  padding: 0;
  list-style: none;

  @include for-tablet {
    margin: var(--spacer--20) var(--spacer-18) var(--spacer-10);
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(235, 103, 71, 0.3);

    &:hover {
      background-color: var(--orange);
    }
  }

  @include for-desktop {
    height: auto;
    margin: 0 0 var(--spacer-10) 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    flex-direction: row;
    margin-left: var(--spacer-20);
    padding: var(--spacer-10) var(--spacer-10) var(--spacer-10) 0;
    font-size: var(--font-sm);
    color: var(--black);
    font-weight: normal;
    cursor: pointer;
    border-radius: var(--spacer-10);

    &--count {
      margin-left: var(--spacer-10);
      color: var(--dark-gray);
    }

    &:hover {
      color: var(--orange);

      span {
        color: var(--orange);
      }
    }

    &:nth-child(1) {
      padding: var(--spacer-20) 0 var(--spacer-10);
    }

    &:last-child {
      padding: var(--spacer-10) 0 var(--spacer-20);
    }

    &--disable {
      display: none;
    }

    &--active {
      color: var(--orange);

      span {
        color: var(--orange);
      }
    }
  }
}
</style>
