<template>
  <ul
    class="m-brands-list-brand"
    :class="{'show-more-trigger': isShow}"
  >
    <li
      class="m-brands-list-brand-item"
      v-for="(brandGroup, index) in arrayBrands"
      v-if="brandGroup && brandGroup.length > 0"
      :key="index"
    >
      <p class="m-brands-list-brand-item__title">
        {{ index }}
        <span
          class="m-brands-list-brand-item__title--count"
          v-html="brandGroup.length"
        />
        <span
          class="m-brands-list-brand-item__title--label"
          v-html="totalBrandsLabel(brandGroup.length)"
        />
      </p>
      <ul class="m-brands-list-brand-sublist">
        <li
          class="m-brands-list-brand-sublist-item"
          v-for="brand in brandGroup.isShow ? brandGroup : sliceArrayBrands(brandGroup)"
          :key="brand.id"
        >
          <SfLink
            :link="localizedRoute(`/${brand.url_path}`)"
            class="m-brands-list-brand-sublist-item__title"
          >
            {{ brand.name }}
          </SfLink>
        </li>
        <li
          v-if="brandGroup.isShow"
          class="m-brands-list-brand-sublist-item m-brands-list-brand-sublist-item--more"
          data-transaction-name="Brands Show More"
          @click="showMore(brandGroup)"
        >
          {{ $t("Hide brands") }}
          <span class="m-brands-list-brand-sublist-item--more__letter">«{{ index }}»</span>
        </li>
        <li
          v-else-if="brandGroup.length > 11"
          class="m-brands-list-brand-sublist-item m-brands-list-brand-sublist-item--more"
          data-transaction-name="Brands Show More"
          @click="showMore(brandGroup)"
        >
          {{ $t("All brands") }}
          <span class="m-brands-list-brand-sublist-item--more__letter">«{{ index }}»</span>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { declension } from 'theme/helpers/text';
import { SfLink } from '@storefront-ui/vue';

export default {
  name: 'MBrandsListBrand',
  components: { SfLink },
  props: {
    sortByNumber: {
      type: Boolean,
      default: false
    },
    brandGroups: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isShow: ''
    };
  },
  computed: {
    arrayBrands () {
      const newSortList = {}
      for (let val in this.brandGroups) {
        if (val !== '0-9') {
          newSortList[val] = this.alphabeticalSorting(this.brandGroups[val])
        }
      }

      return !this.sortByNumber ? Object.assign(newSortList, { '0-9': this.brandGroups['0-9'] }) : this.brandGroups;
    }
  },
  methods: {
    totalBrandsLabel (count) {
      return declension([this.$t('brand'), this.$t('brands'), this.$t('brands')], count)
    },
    sliceArrayBrands (array) {
      return array.slice(0, 11)
    },
    showMore (item) {
      this.isShow = !this.isShow
      item.isShow = !item.isShow;
    },
    alphabeticalSorting (array) {
      return array.sort((a, b) => {
        const firstA = a.name;
        const secondB = b.name;

        return firstA.localeCompare(secondB, ['en', 'uk'], { sensitivity: 'base' });
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-brands-list-brand {
  margin: var(--spacer-20) 0 0;
  padding: 0;
  list-style: none;

  @include for-desktop {
    margin: 0;
  }

  &-item {
    margin-bottom: var(--spacer-30);
    padding-bottom: var(--spacer-20);
    border-bottom: 1px solid var(--gray3);

    &__title {
      margin: 0;
      padding: 0;
      font-size: var(--font-size-24);
      color: var(--black);
      text-transform: uppercase;

      &--count, &--label {
        font-size: var(--font-size-13);
        color: var(--dark-gray);
        text-transform: lowercase;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &-sublist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: var(--spacer-20);
    padding: 0;
    list-style: none;
    position: relative;

    @media (min-width: $tablet-min) {
      margin-top: var(--spacer-30);
    }

    &:last-of-type {
      &:after {
        content: "";
        display: inline-block;
        height: 25px;
        width: 50%;

        @media (min-width: $tablet-min) {
          width: 33%;
        }
      }
    }

    &-item {
      width: 50%;
      margin-bottom: var(--spacer-10);

      @media (min-width: $tablet-min) {
        width: 33%;
      }

      &__title {
        margin: 0;
        padding: 0;
        font-size: var(--font-size-14);
        color: var(--black);
        cursor: pointer;

        &:hover {
          color: var(--orange);
        }
      }

      &--more {
        margin: 0;
        padding: 0;
        font-size: var(--font-size-14);
        color: var(--orange);
        cursor: pointer;
        position: absolute;
        bottom: 12px;
        right: 0;

        @media (min-width: $tablet-min) {
          right: 9px;
        }

        &__letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
