<template>
  <ul class="m-brands-tabs-mob">
    <li
      class="m-brands-tabs-mob-tab m-brands-tabs-mob-tab--category"
      :class="{'m-brands-tabs-mob-tab--show': showFilterCategory}"
      @click="triggerTabCategory"
    >
      <p class="m-brands-tabs-mob-tab__title">
        {{ $t('Categories') }}
      </p>
      <p class="m-brands-tabs-mob-tab__name">
        {{ tabCategotyName }}
      </p>
    </li>
    <li
      class="m-brands-tabs-mob-tab m-brands-tabs-mob-tab--alphabet"
      :class="{'m-brands-tabs-mob-tab--show': showFilterAlphabet}"
      @click="triggerTabAlphabet"
    >
      <p class="m-brands-tabs-mob-tab__title">
        {{ $t('Brands alphabetically') }}
      </p>
      <p class="m-brands-tabs-mob-tab__name">
        {{ tabAlphabetName }}
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MBrandsTabsMob',
  components: {
  },
  props: {
    activeCategory: {
      type: Object,
      default: null
    },
    activeAlphabet: {
      type: String,
      default: 'all'
    },
    showFilterCategory: {
      type: Boolean,
      default: false
    },
    showFilterAlphabet: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tabCategotyName () {
      return this.activeCategory.name
    },
    tabAlphabetName () {
      return this.activeAlphabet === 'all' ? this.$t('All brands') : this.activeAlphabet
    }
  },
  methods: {
    triggerTabCategory () {
      this.$emit('triggerTabCategory');
    },
    triggerTabAlphabet () {
      this.$emit('triggerTabAlphabet');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-brands-tabs-mob {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 var(--spacer-10);
  list-style: none;
  margin: 0 0 2px;

  @include for-tablet {
    padding: 0 var(--spacer-18);
  }

  @include for-desktop {
    display: none;
  }

  &-tab {
    width: 49%;
    padding: var(--spacer-8) var(--spacer-15);
    background: var(--accordion-background);
    border-radius: var(--spacer-5);
    box-sizing: border-box;
    position: relative;

    &--alphabet {
      background: var(--light-gray);
    }

    &__title {
      margin: 0;
      font-size: var(--font-size-12);
      color: var(--dark-gray);
    }

    &__name {
      margin: 0;
      width: 85%;
      font-size: var(--font-size-14);
      color: var(--black);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:after {
      content: '';
      display: inline;
      position: absolute;
      height: var(--spacer-3);
      width: var(--spacer-3);
      top: 0;
      bottom: 0;
      right: var(--spacer-18);
      border: 2px solid var(--black);
      border-left-width: 0;
      border-top-width: 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      margin: auto 0;
      -webkit-transition: -webkit-transform .1s ease;
      transition: -webkit-transform .1s ease;
      -o-transition: transform .1s ease;
      transition: transform .1s ease, -webkit-transform .1s ease;
      cursor: pointer;
    }

    &--show {
      &:after {
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        transform: rotate(225deg);
      }
    }
  }
}
</style>
