import { RobotsEnum } from './types'
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';

export function metaBrands () {
  const relRobots = relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);
  const h1 = this.getBrandsCategory?.meta_h1 || this.getBrandsCategory?.name || this.$t('Brands')
  const metaTitle = this.getBrandsCategory?.meta_title || `${h1} | VARUS`
  const metaDescription = this.getBrandsCategory?.meta_description || `${h1} ${this.$t('META_OTHER_PAGES_DESCRIPTION')}`

  const meta = {
    title: metaTitle,
    meta: {
      ...relRobots,
      description: {
        name: 'description',
        content: metaDescription
      }
    }
  };

  return mergeMeta(meta)
}
