<template>
  <div
    class="filter-alphabet-wrapper"
    :class="{'filter-alphabet--show': showFilterAlphavet}"
  >
    <div class="filter-alphabet">
      <div class="filter-alphabet-all-brands">
        <button
          class="filter-alphabet-all-brands__button"
          :class="{'filter-alphabet-all-brands__button--active': activeAlphabet === 'all'}"
          data-transaction-name="Brands Filter Alphabet All"
          @click="filterByAlphabet('all')"
        >
          {{ $t('All brands') }}
        </button>
      </div>
      <div class="filter-alphabet-list">
        <ul class="filter-alphabet-lang filter-alphabet-lang--english">
          <li
            class="filter-alphabet-lang__elem"
            v-for="(brandGroup, index) in numbersAlphabet"
            v-if="brandGroup.length > 0"
            :class="[{'filter-alphabet-lang__elem--disabled': isDisable(index)},
                     {'filter-alphabet-lang__elem--active': isActive(index) }]"
            :key="index"
            data-transaction-name="Brands Filter Alphabet"
            @click="filterByAlphabet(index)"
          >
            {{ index }}
          </li>
          <li
            class="filter-alphabet-lang__elem"
            v-for="(brandGroup, index) in latinAlphabet"
            v-if="brandGroup.length > 0"
            :key="index"
            :class="[{'filter-alphabet-lang__elem--disabled': isDisable(index)},
                     {'filter-alphabet-lang__elem--active': isActive(index) }]"
            data-transaction-name="Brands Filter Alphabet - ELM"
            @click="filterByAlphabet(index)"
          >
            {{ index }}
          </li>
        </ul>
        <ul class="filter-alphabet-lang filter-alphabet-lang--ua">
          <li
            class="filter-alphabet-lang__elem"
            v-for="(brandGroup, index) in cyrillicAlphabet"
            v-if="brandGroup.length > 0"
            :key="index"
            :class="[{'filter-alphabet-lang__elem--disabled': isDisable(index)},
                     {'filter-alphabet-lang__elem--active': isActive(index) }]"
            data-transaction-name="Brands Filter Alphabet - ELM"
            @click="filterByAlphabet(index)"
          >
            {{ index }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MBrandsFilterAlphabet',
  props: {
    activeAlphabet: {
      type: String,
      default: 'all'
    },
    brandGroups: {
      type: Object,
      default: () => ({})
    },
    brandGroupsAlphabet: {
      type: Object,
      default: () => ({})
    },
    showFilterAlphavet: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    latinAlphabet () {
      return this.brandGroupsAlphabet.latin.brandsByLetter;
    },
    numbersAlphabet () {
      return this.brandGroupsAlphabet.numbers.brandsByLetter;
    },
    cyrillicAlphabet () {
      return this.brandGroupsAlphabet.cyrillic.brandsByLetter;
    }
  },
  methods: {
    filterByAlphabet (brand) {
      this.$emit('filterByAlphabet', brand);
    },
    isDisable (key) {
      return this.brandGroups[key] === undefined
    },
    isActive (key) {
      return key.toUpperCase() === this.activeAlphabet
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.filter-alphabet-wrapper {
  display: none;
  width: 100%;
  order: 2;
  margin:var(--spacer--20) 0 var(--spacer-10);
  padding: 0 var(--spacer-10);

  &.filter-alphabet--show {
    display: block;
  }

  @include for-tablet {
    padding: 0 var(--spacer-18);
  }

  @include for-desktop {
    display: block;
    padding: 0 var(--spacer-16);
    margin: 0;
  }
}

.filter-alphabet {
  width: auto;
  display: flex;
  flex-direction: column;
  padding: var(--spacer-15);
  background: var(--light-gray);
  border-radius: var(--spacer-10);
  box-sizing: border-box;

  @include for-desktop {
    flex-direction: row;
    align-items: center;
    margin: var(--spacer-20) 0 var(--spacer-5);
    padding: var(--spacer-24) var(--spacer-30);
  }

  &-all-brands {
    margin-bottom: var(--spacer-20);
    padding-bottom: var(--spacer-20);
    border-bottom: 1px solid var(--gray3);

    @include for-desktop {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }

    &__button {
      margin-right: var(--spacer-33);
      padding: 0;
      border: none;
      background: none;
      outline: none;
      font-size: var(--font-size-14);
      color: var(--black);
      cursor: pointer;

      &--active {
        color: var(--orange);
      }
    }
  }

  &-list {
    width: 100%;

    @include for-desktop {
      width: 87%;
    }
  }

  &-lang:first-of-type {
    li:first-of-type {
      order: 1;
    }
  }

  &-lang {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0 0 0 var(--spacer-2);
    list-style: none;

    &__elem {
      min-width: var(--spacer-18);
      display: flex;
      justify-content: center;
      margin: 0 var(--spacer-11) var(--spacer-10) 0;
      font-size: var(--font-size-14);
      color: var(--black);
      text-transform: uppercase;
      cursor: pointer;

      @include for-desktop {
        min-width: auto;
        margin: 0 var(--spacer-20) var(--spacer-5) 0;
      }

      &:hover {
        color: var(--orange);
      }

      &--disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
      }

      &--active {
        color: var(--orange);
      }
    }

    &--ua {
      margin-top: var(--spacer-10);
      padding-top: var(--spacer-20);
      border-top: 1px solid var(--gray3);

      @include for-desktop {
        padding-top: var(--spacer-15);
      }
    }
  }
}
</style>
